.contentWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1000px;
  margin-top: 100px;
}

.content {
  display: flex;
  flex-direction: row;
  background-color: #050b0c;
  border-radius: 32px;
  width: 1000px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 12px 24px 24px;
}

.rightColumn {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 24px 24px 12px;
}

.articleContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #243135;
  border-radius: 10px;
  padding: 0 20px 40px;
  max-height: 375px;
  overflow: hidden;
  transition: max-height 1s ease;
}

.articleContainerExpanded {
  max-height: 1500px;
  height: auto;
}

.overlay {
  opacity: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(5, 11, 12, 0), #050b0c);
  transition: opacity 1s ease;
}

.overlayHidden {
  opacity: 0;
}
