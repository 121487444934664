.row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 32px;
}

.header {
  display: flex;
  flex-grow: 1;

  &_text {
    font-size: 30px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    max-width: 800px !important;
  }
}
