.container {
  display: flex;
  flex-direction: row;

  &_leftColumn {
    display: flex;
    width: 250px;
    flex-direction: column;
    padding: 0px 32px 75px 0px;
  }

  &_rightColumn {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &_row {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
      height: 90px;
    }
  }
}

.singleCell {
  display: flex;
  width: calc(50% - 8px);
}

.cellRight {
  display: flex;
  flex-grow: 1;
  width: 50%;
  margin-left: 8px;
}

.cellLeft {
  display: flex;
  flex-grow: 1;
  width: 50%;
  margin-right: 8px;
}
