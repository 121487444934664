.dialog {
  background-color: #fc6d4b;
  border-radius: 32px;
  color: #24251e;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.paperComponent {
  width: 640px;
  border-radius: 32px;
}

.content {
  display: flex;
  flex-grow: 1;
  background-color: rgba(60, 72, 76, 0.2);
  border-radius: 20px;
  color: #fff;
  padding: 20px;
  margin-bottom: 16px;
}

.copyButton {
  border-radius: 10px;
  background-color: #3c484c;
  box-shadow: none;
  color: #f2f2f3;
}
