.stepIndicator {
  width: 8px;
  border-radius: 20px;
  background-color: #fff;
  height: 8px;
  margin: 4px 4px 0px 0px;
}

.activeStepIndicator {
  width: 32px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
}

.pageIndicatorRow {
  margin-bottom: 24px;
}

.supportPlayerRow {
  margin-bottom: 32px;
}
