body {
  display: flex;
  margin: 0;
  min-height: 100vh;
  min-width: 420px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  scrollbar-gutter: stable both-edges;
  /* overflow-x: hidden; */
}

#root {
  width: 100%;
}
