.container {
  display: flex;
  flex-direction: row;

  &_leftColumn {
    display: flex;
    width: 250px;
    flex-direction: column;
    padding: 75px 32px 75px 0px;
  }

  &_rightColumn {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.actions {
  display: flex;
  width: 150px;
}

.menuItem {
  border-radius: 20px;

  &_icon {
    color: #fafcf2;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &_closeButton {
    padding: 0px;
    height: 35px;
    width: 35px;
    min-width: 35px;
    border-radius: 10px;
    background-color: #3c484c;
    color: #f2f2f3;
  }
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  // height: 90px;
  &_auto {
    height: auto;
  }
}

.column {
  display: flex;
  width: calc(50% - 8px);

  &_fullWidth {
    width: calc(100% - 8px);
  }

  &_right {
    display: flex;
    flex-grow: 1;
    width: 50%;
    margin-left: 8px;
  }

  &_left {
    display: flex;
    flex-grow: 1;
    width: 50%;
    margin-right: 8px;
  }
}

.cell {
  color: #fff !important;
}

.completedcell {
  color: #038900 !important;
}
