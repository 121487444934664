.wrapper {
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  min-width: 148px;
  align-self: stretch;
  background-color: #c4d3d6;
  border-radius: 8px;
  margin: 0px;
}

.text {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

  &_label {
    color: #7a98a2;
  }

  &_value {
    color: #fafcf2;
  }
}
