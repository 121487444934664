.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  display: flex;
  align-items: center;
}

.icon {
  color: red;
  padding: 20px;

  &_size {
    font-size: 125px;
  }
}
