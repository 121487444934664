.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.closeButton.closeButton {
  padding: 0px;
  height: 35px;
  width: 35px;
  min-width: 35px;
  border-radius: 10px;
  background-color: #3c484c;
  color: #f2f2f3;
}
