.pageContentWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1000px;
  margin-top: 100px;
}

.pageContent {
  display: flex;
  flex-direction: row;
  background-color: #05080c;
  border-radius: 32px;
  width: 1000px;
  color: #fafcf2;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
}
