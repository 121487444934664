.playerThumbnailContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 256px;
  height: 355px;
  /* border: 1px solid rgba(250, 250, 250, 0.1); */
  border-radius: 24px;
  overflow: hidden;
}

.playerThumbnailContainer-light {
  box-shadow: none;
  margin: 0px;
  background-color: transparent;
}

.playerThumbnailContainer-dark {
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25); */
  margin: 64px 32px;
  background-color: transparent;
  transform: scale(1);
  transition: transform 0.25s;
}

.playerThumbnailContainer-dark:hover {
  /* box-shadow:
    0px 0px 0px 8px rgba(255, 255, 255, 0.19),
    0px 4px 6px 0px rgba(0, 0, 0, 0.25); */
  cursor: pointer;
  transform: scale(1.1);
  transition: transform 0.25s;
}

.playerThumbnailBlurContainer {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(18, 37, 41, 0.6) 0%, #19232599 100%);
  padding: 16px;
  min-height: 85px;
  justify-content: flex-end;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.playerThumbnailBorderRadius-10 {
  border-radius: 10px;
}

.playerThumbnailBorderRadius-24 {
  border-radius: 24px;
}
