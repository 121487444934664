.carousel {
  width: 100%;
  min-width: 600px;
}

.carouselText {
  color: #fff;
  min-width: 600px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 8px 16px 0;
  height: 48px;

  &_name {
    @supports (-webkit-line-clamp: 1) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &_description {
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.carouselAction {
  display: flex;
  justify-content: flex-end;
  margin: 8px 32px 0;
}
