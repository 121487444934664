.supportStepRow {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  height: auto;
}

.progressRowWrapper {
  display: flex;
  flex-grow: 1;
  width: 100% !important;
  flex-direction: column;
}

.supportStepProgressRow {
  margin-bottom: 24px;
}

.supportStepDetailsRow {
  justify-content: space-between;
  margin-bottom: 32px;
}

.supportStepSharesRow {
  justify-content: space-between;
  margin-bottom: 32px;
}

.supportStepProgressBarText {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  color: #fafcf2;
  justify-content: space-between;
}

.supportStepRemainingCampaignTime {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  color: #fafcf2;
}

.supportStepDetails {
  background-color: rgba(60, 72, 76, 0.2);
  padding: 20px;
  border-radius: 20px;
  color: #fff;
}

.supportStepDetailsMedium {
  width: 210px;
}

.supportStepDetailsLarge {
  width: 430px;
}

.supportStepCounter {
  display: flex;
  flex-direction: row;
  width: 300px;
  align-items: center;
  justify-content: space-between;
}

.supportStepTotalPrice {
  display: flex;
  width: 350px;
  justify-content: space-between;
  align-items: center;
}
