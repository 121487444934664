.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 2900;
}
